export const LOGIN = "/";
export const LOGIN_CALLBACK = "/login/callback";
export const FORGOT_PASSWORD = "/forgot-password";
export const LOGOUT = "/logout";
export const BROKER_DASHBOARD = "broker-dashboard";
export const VIEW_ELIGIBILITY = "view-eligibility";
export const EMPLOYER_NEW_RENEWING = "employer-new-renewing";
export const EMPLOYER_BENEFITS_ENROLLMENT = "employer-benefits-enrollment";
export const INDIVIDUAL_NEW_RENEWING = "individual-new-renewing";
export const MEDICARE_ENROLLMENT_2025 = "medicare-enrollment-2025";
export const MEDICARE_ENROLLMENT = "medicare-enrollment";
export const MEDICARE_BOOK_BUSINESS = "medicare-book-business";
export const MEDICARE_COMMISSION = "medicare-book-of-business";
export const COMMISSION_ACTIVITY = "commission-activity";
export const COMMISSION_INFORMATION = "commission-information";
export const BROKER_BOOK_BUSINESS_REPORTING = "broker-book-business-reporting";
export const BROKER_REWARDS_PROGRAM = "broker-rewards-program";
export const UNPAID_GROUP_BALANCE = "unpaid-group-balance";
export const ORDER_MEMBER_ID_CARD = "order-member-id-card";
export const CHANGE_PASSWORD = "change-password";
export const CHANGE_PASSWORD_UPDATE = "change-password-update";
export const DEVICE_MANAGEMENT_PORTAL = "device-management-portal";
export const CONTACTUS = "contact-us";
export const CONTACTUS_SUCCESS = "contact-us-success";
export const SECRE_QUESTION_ANSWER = "secret-question-answer";
export const COMMISSION_STATEMENT = "commission-statement";
export const GROUP_REPORTING = "group-reporting";
export const OVERVIEW = "overview";
export const ENROLLMENT = "enrollment";
export const DEVICE_MANAGEMENT = "";
export const DEVICE_MANAGEMENT_FAQ = "faq";
export const ENROLL_SMART_PHONE = "smart-phone";
export const ENROLL_TABLET = "tablet";
export const ENROLL_PHONE = "phone";
