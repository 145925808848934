import { PasswordHelp } from "./Navigation";
import {
  BROKER_BOOK_BUSINESS_REPORTING,
  BROKER_REWARDS_PROGRAM,
  COMMISSION_ACTIVITY,
  COMMISSION_INFORMATION,
  COMMISSION_STATEMENT,
  DEVICE_MANAGEMENT_PORTAL,
  EMPLOYER_BENEFITS_ENROLLMENT,
  EMPLOYER_NEW_RENEWING,
  GROUP_REPORTING,
  INDIVIDUAL_NEW_RENEWING,
  MEDICARE_BOOK_BUSINESS,
  MEDICARE_ENROLLMENT,
  MEDICARE_ENROLLMENT_2025,
  ORDER_MEMBER_ID_CARD,
  UNPAID_GROUP_BALANCE,
  VIEW_ELIGIBILITY,
  MEDICARE_COMMISSION,
} from "./Routes";

const AccessMap: { [x: string]: string } = {};
AccessMap[`/app/${VIEW_ELIGIBILITY}`] = "ViewEligibility";
AccessMap[`/app/${EMPLOYER_NEW_RENEWING}`] = "EmployerNewRenewing";
AccessMap[`/app/${EMPLOYER_BENEFITS_ENROLLMENT}`] =
  "EmployerBenefitsEnrollment";
AccessMap[`/app/${GROUP_REPORTING}`] = "EmployerGroupReporting";
AccessMap[`/app/${INDIVIDUAL_NEW_RENEWING}`] = "IndividualNewRenewing";
AccessMap[`/app/${MEDICARE_ENROLLMENT}`] = "MedicareEnrollment";
AccessMap[`/app/${MEDICARE_ENROLLMENT_2025}`] = "MedicareEnrollment2025";
AccessMap[`/app/${MEDICARE_BOOK_BUSINESS}`] = "MedicareBookOfBusiness";
AccessMap[`/app/${MEDICARE_COMMISSION}`] = "MedicareBookOfBusiness";
AccessMap[`/app/${COMMISSION_STATEMENT}`] = "CommissionStatement";
AccessMap[`/app/${COMMISSION_ACTIVITY}`] = "CommissionActivity";
AccessMap[`/app/${COMMISSION_INFORMATION}`] = "CommissionInformation";
AccessMap[`/app/${BROKER_BOOK_BUSINESS_REPORTING}`] =
  "BrokerBookOfBusinessReporting";
AccessMap[`/app/${BROKER_REWARDS_PROGRAM}`] = "BrokerRewardsProgram";
AccessMap[`/app/${UNPAID_GROUP_BALANCE}`] = "UnpaidGroupBalance";
AccessMap[`/app/${ORDER_MEMBER_ID_CARD}`] = "OrderMemberIdCard";
AccessMap[`${PasswordHelp}`] = "PasswordHelp";
AccessMap[`/${DEVICE_MANAGEMENT_PORTAL}`] = "DeviceManagementPortal";

export { AccessMap };
