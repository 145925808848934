import SendMePushImg from "../../assets/images/sTwelve.png";
import DuoAppApprove from "../../assets/images/sThirteen.png";
import DuoMobilePushApple from "../../assets/images/aOne.png";
import DuoMobilePushAndroid from "../../assets/images/aTwo.png";
import KeyIconAppleExperience from "../../assets/images/aThree.png";
import KeyIconAndroidExperience from "../../assets/images/aFour.png";
import EnterPassCode from "../../assets/images/aFive.png";
import TextMeNewCode from "../../assets/images/aSix.png";
import EnterFirstCode from "../../assets/images/aEight.png";
import ReceivePhoneCall from "../../assets/images/aNine.png";
import PressOneOnKeypad from "../../assets/images/aTen.png";
import SignInForEnroll from "../../assets/images/SignIn.png";
import StartSetUp from "../../assets/images/eTwo.png";
import TypeOfDevice from "../../assets/images/eThree.png";
import S_One from "../../assets/images/sOne.png";
import S_Two from "../../assets/images/sTwo.png";
import S_Three from "../../assets/images/sThree.png";
import S_Four from "../../assets/images/sFour.png";
import S_Five from "../../assets/images/sFive.png";
import S_Six from "../../assets/images/sSix.png";
import S_Seven from "../../assets/images/sSeven.png";
import S_Eight from "../../assets/images/sEight.png";
import S_Nine from "../../assets/images/sNine.png";
import S_Ten from "../../assets/images/sTen.png";
import S_Eleven from "../../assets/images/sEleven.png";
import S_Fourteen from "../../assets/images/sFourteen.png";
import S_Fifteen from "../../assets/images/sFifteen.png";
import T_One from "../../assets/images/tOne.png";
import T_Two from "../../assets/images/tTwo.png";
import T_Three from "../../assets/images/tThree.png";
import T_Four from "../../assets/images/tFour.png";
import T_Five from "../../assets/images/tFive.png";
import T_Six from "../../assets/images/tSix.png";
import P_One from "../../assets/images/pOne.png";
import P_Two from "../../assets/images/pTwo.png";
import P_Six from "../../assets/images/pSix.png";
import P_Seven from "../../assets/images/pSeven.png";
import P_Eight from "../../assets/images/pEight.png";
import P_Nine from "../../assets/images/pNine.png";
import P_Ten from "../../assets/images/pTen.png";
import P_Eleven from "../../assets/images/pEleven.png";
import P_Twelve from "../../assets/images/pTwelve.png";
import aOne_AvMed from "../../assets/images/aOne_AvMed.png";
import aTwo_AvMed from "../../assets/images/aTwo_AvMed.png";
import aThree_AvMed from "../../assets/images/aThree_AvMed.png";
import aFour_AvMed from "../../assets/images/aFour_AvMed.png";
import sThirteen_AvMed from "../../assets/images/sThirteen_AvMed.png";
import StartSetUp_AvMed from "../../assets/images/eTwo_AvMed.png";

export const spanDaysDefault = "15";

export const LINK = {
  BROKERHOME_LINK: "https://www.sentarahealthplans.com/brokers",
  AGENTHOME_LINK: "https://www.avmed.com/web/business/brokers/",
  updateFeature: "updateFeature",
  VIRGINIA_GOV_LINK:
    "https://www.scc.virginia.gov/pages/Administrative-Letters-to-be-Reviewed",
  PRIVACY_POLICY:
    "https://www.sentarahealthplans.com/company/policies/privacy-statement",
  PRIVACY_POLICY_AVMED: "https://www.avmed.com/your-privacy",
  COMMISSION_POLICY:
    "https://apps.sentarahealthplans.com/brokers/sso/SAPSSO.ashx",
  EMPLOYER_BENEFITS_ENROLLMENT:
    "https://apps.sentarahealthplans.com/brokers/sso/SAPSSO.ashx",
  EMPLOYER_NEW_SSO:
    "https://apps.sentarahealthplans.com/brokers/sso/SAPSSO.ashx",
  MEDICARE_ENROLLMENT_SSO:
    "https://apps.sentarahealthplans.com/brokers/sso/EngagentSSO.ashx",
  MEDICARE_BOOK_BUSINESS_SSO:
    "https://apps.sentarahealthplans.com/brokers/sso/SAPSSO.ashx",
  COMMISSION_ACTIVITY_SSO:
    "https://tableau.sentara.com/t/optimabrokers/views/OptimaHealthBrokerDashboard_WeeklyCommissions/BrokerCommissionsDashboard?iframeSizedToWindow=true&%3Aembed=y&%3AshowAppBanner=false&%3Adisplay_count=no&%3AshowVizHome=no&%3Atabs=no#2",
  BROKER_BOOK_BUSINESS_SSO:
    "https://tableau.sentara.com/t/optimabrokers/views/OptimaHealthBrokerDashboard_BookofBusiness/BrokerDashboard?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&:tabs=no#1",
  FREQUENTLY_ASKED_QUESTION:
    "https://www.sentarahealthplans.com/brokers/frequently-asked-questions",
  INDIVIDUAL_NEW_RENEWING_SSO:
    "https://apps.sentarahealthplans.com/brokers/sso/IndividualRenewals.aspx",
  FORGOT_PASSWORD_QA:
    "https://sentaratest.service-now.com/$pwd_reset.do?sysparm_url=sentara_pwd_reset",
  FORGOT_PASSWORD_DEV:
    "https://sentaradev.service-now.com/$pwd_reset.do?sysparm_url=sentara_pwd_reset",
  FORGOT_PASSWORD_PROD:
    "https://sentara.service-now.com/$pwd_reset.do?sysparm_url=sentara_pwd_reset",
  BASE_URL_QA: "https://sen-dmz-dev-fa-fhir-e1-02.azurewebsites.net/api",
  BASE_URL_DEV: "https://digitalapim.dev.sentara.com/sentarafhirapi",
  SERVICE_NOW_PASSWORD_ENROLLMENT:
    "https://sentara.service-now.com/$pwd_enrollment_form_container.do",
  CONTACT_US_LINK: "https://www.sentarahealthplans.com/brokers/contact-us",
  CONTACT_US_LINK_BRAND: "https://www.avmed.com/brokers/contact-us",
  LEARN_MORE_LINK_DASHBOARD:
    "https://www.sentarahealthplans.com/brokers/we-offer-brokers-more",
  LEARN_MORE_LINK_DASHBOARD_BRAND:
    "https://www.avmed.com/brokers/we-offer-brokers-more",
};

export const COMMISSION_POLICY = {
  ENTROLLED1: "1-3",
  ENTROLLED2: "4-14",
  ENTROLLED3: "15-50",
  ENTROLLED4: "51-150",
  NEWSALES1: "$31.00",
  NEWSALES2: "$41.00",
  NEWSALES3: "$34.00",
  NEWSALES4: "$27.00",
  RENEWAL1: "$21.00",
  RENEWAL2: "$31.00",
  RENEWAL3: "$28.00",
  RENEWAL4: "$21.00",
  ELIGIBLE_EMPLOYEES1: "151-250",
  ELIGIBLE_EMPLOYEES2: "251-750",
  ELIGIBLE_EMPLOYEES3: "751 +",
  RATE1: "Standard = 3% of premium",
  RATE2: "Standard = 2.25% of premium",
  RATE3: "Standard = 1% of premium",
};

export const HOME = "Home";

export const VALIDATION_LENGTH = {
  POLICY_ID_MAX_LENGTH: 9,
  MEMBER_ID_MAX_LENGTH: 20,
  SSN_MIN_LENGTH: 9,
  NAME_MAX_LENGTH: 20,
  ORGANIZATION_MAX_LENGTH: 200,
  EMAIL_MAX_LENGTH: 100,
  ADDRESS_MAX_LENGTH: 60,
  CITY_MAX_LENGTH: 40,
  POSTALCODE_MAX_LENGTH: 15,
  PHONENUMBER_MAX_LENGTH: 10,
  PASSWORD_MAX_LENGTH: 64,
  MBI_MAX_LENGTH: 13
};

export const groupSizeItems = [
  {
    id: 1,
    value: "2-99",
  },
  {
    id: 2,
    value: "100+",
  },
];

export const contactMode = [
  {
    id: 1,
    value: "contactus.phone",
    testId: "chkContactUsContactViaPhone",
  },
  {
    id: 2,
    value: "contactus.mail",
    testId: "chkContactUsContactViaMail",
  },
  {
    id: 3,
    value: "contactus.email",
    testId: "chkContactUsContactViaEmail",
  },
];

export const listPerTable = 10;
export const memberNumberLength = 8;
export const SSNLength = 9;
export const MBILength = 11;
export const subscriberMinLength = 6;

export const IDLETIMER = {
  delay: 840 * 1000,
  timeout: 60000,
};

export const DEVICE_MANAGEMENT_IMAGE = {
  image1: SendMePushImg,
  image2: DuoAppApprove,
  image3: DuoMobilePushApple,
  image4: DuoMobilePushAndroid,
  image5: KeyIconAppleExperience,
  image6: KeyIconAndroidExperience,
  image7: EnterPassCode,
  image8: TextMeNewCode,
  image9: EnterFirstCode,
  image10: ReceivePhoneCall,
  image11: PressOneOnKeypad,
  image12: SignInForEnroll,
  image13: StartSetUp,
  image14: TypeOfDevice,
  image15: S_One,
  image16: S_Two,
  image17: S_Three,
  image18: S_Four,
  image19: S_Five,
  image20: S_Six,
  image21: S_Seven,
  image22: S_Eight,
  image23: S_Nine,
  image24: S_Ten,
  image25: S_Eleven,
  image28: S_Fourteen,
  image29: S_Fifteen,
  image30: T_One,
  image31: T_Two,
  image32: T_Three,
  image33: T_Four,
  image34: T_Five,
  image35: T_Six,
  image36: P_One,
  image37: P_Two,
  image38: P_Six,
  image39: P_Seven,
  image40: P_Eight,
  image41: P_Nine,
  image42: P_Ten,
  image43: P_Eleven,
  image44: P_Twelve,
  image45: aOne_AvMed,
  image46: aTwo_AvMed,
  image47: aThree_AvMed,
  image48: aFour_AvMed,
  image49: sThirteen_AvMed,
  image50: StartSetUp_AvMed,
};

export const SERVICE_NUMBER = {
  GROUP_BUSINESS_NUMBER: "800-835-6131",
  INDIVIDUAL_MEDICARE_NUMBER: "800-461-2950",
  BROKER_SERVICE_NUMBER: "757-552-7217",
  BROKER_SERVICE_NUMBER_1: "1-866-927-4785",
};

export const MEMBERSTATUS = {
  E: "Enrolled",
  D: "Disenrolled",
};

export const MemberRelationship = {
  D: "Dependent",
  P: "Subscriber",
  S: "Spouse",
};

export const Gender = {
  M: "Male",
  F: "Female",
};

export const GroupStatus = {
  A: "status.active",
  I: "status.inactive",
};


export const APPLICATION_TYPE = "BROKER";

// Dashboard Tiles
export const TilesIds = {
  Title1: "tile1",
  Title2: "tile2",
  Title3: "tile3",
  Title4: "tile4",
  Title5: "tile5",
  Title6: "tile6",
  Title7: "tile7",
  Title8: "tile8",
  Title9: "tile9",
};

