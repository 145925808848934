import {
  BROKER_BOOK_BUSINESS_REPORTING,
  BROKER_DASHBOARD,
  BROKER_REWARDS_PROGRAM,
  COMMISSION_ACTIVITY,
  COMMISSION_INFORMATION,
  COMMISSION_STATEMENT,
  CONTACTUS,
  DEVICE_MANAGEMENT_PORTAL,
  EMPLOYER_BENEFITS_ENROLLMENT,
  GROUP_REPORTING,
  INDIVIDUAL_NEW_RENEWING,
  MEDICARE_BOOK_BUSINESS,
  MEDICARE_COMMISSION,
  MEDICARE_ENROLLMENT,
  MEDICARE_ENROLLMENT_2025,
  ORDER_MEMBER_ID_CARD,
  UNPAID_GROUP_BALANCE,
  VIEW_ELIGIBILITY,
} from "./Routes";
export const Needhelp =
  "https://www.sentarahealthplans.com/company/about/contact-optima-health";
export const PasswordHelp = process.env.REACT_APP_PASSWORD_HELP_URL;
export const ContactLink = `/app/${CONTACTUS}`;
export const memberBaseUrl = "https://www.sentarahealthplans.com";
export const avMedBaseUrl = "https://www.avmed.com";
export const memberBrokerContactUs = `${memberBaseUrl}/brokers/contact-us`;
export const optInToRewards =
  "https://sentara-223793.workflowcloud.com/forms/2da71a9c-83ac-4d6f-a035-6a3578a7c560";
export const brokerRewardsFAQ =
  "https://shc-p-001.sitecorecontenthub.cloud/api/public/content/broker-rewards-program-flyer-faqs";
export const mailLink = "mailto:brokerrelations@sentara.com";
export const programDetails =
  "https://www.sentarahealthplans.com/brokers/we-offer-brokers-more#inc";
export const exploreYourRewards =
  "https://www.1.awardhq.com/3731591DDS0SentaraHealthPlansBrokerRewards";
export const duoSecurity = "https://duosecurity.wistia.com/medias/653b7tkma3";
export const LeftNavigations = [
  {
    title: "nav.member",
    id: "btnQuoteEnrollAndManage",
    icon: "",
    list: [
      {
        title: "nav.menu.viewEligibility",
        id: "lnkViewEligibity",
        to: `/app/${VIEW_ELIGIBILITY}`,
      },
      {
        title: "nav.menu.OrderMemberIDCard",
        id: "lnkOrderMemberIdCard",
        to: `/app/${ORDER_MEMBER_ID_CARD}`,
      },
    ],
  },
  {
    title: "nav.manageYourBookQuoteAndEnroll",
    icon: "",
    list: [
      {
        title: "nav.menu.IndividualNewRenewing",
        id: "lnkIndividualNewAndRenewing",
        to: `/app/${INDIVIDUAL_NEW_RENEWING}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.EmployerBenefitsEnrollment",
        id: "lnkEmployerBenifitsAndEnrollment",
        to: `/app/${EMPLOYER_BENEFITS_ENROLLMENT}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.MedicareAEP",
        id: "lnkMedicareEnrollmentAEP",
        to: `/app/${MEDICARE_ENROLLMENT_2025}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.MedicareEnrollment",
        id: "lnkMedicareEnrollment",
        to: `/app/${MEDICARE_ENROLLMENT}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
    ],
  },
  {
    title: "nav.bookOfBusiness",
    icon: "",
    list: [
      {
        title: "nav.menu.BrokerBookBusinessReporting",
        id: "lnkBrokerBookOfBusinessReporting",
        to: `/app/${BROKER_BOOK_BUSINESS_REPORTING}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.MedicareBookBusiness",
        id: "lnkMedicareBookOfBusiness",
        to: `/app/${MEDICARE_BOOK_BUSINESS}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.GroupReporting",
        id: "lnkGroupReporting",
        to: `/app/${GROUP_REPORTING}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
    ],
  },
  {
    title: "nav.commission",
    icon: "",
    list: [
      {
        title: "nav.menu.CommissionActivity",
        id: "lnkCommissionActivity",
        to: `/app/${COMMISSION_ACTIVITY}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.CommissionInformation",
        id: "lnkCommissionInformation",
        to: `/app/${COMMISSION_INFORMATION}`,
      },
      {
        title: "nav.menu.MedicareBookBusiness",
        id: "lnkMedicareBookOfBusiness",
        to: `/app/${MEDICARE_COMMISSION}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.CommissionStatement",
        id: "lnkCommissionStatement",
        to: `/app/${COMMISSION_STATEMENT}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
      },
      {
        title: "nav.menu.BrokerRewardsProgram",
        id: "lnkBrokerRewardsProgram",
        to: `/app/${BROKER_REWARDS_PROGRAM}`,
      },
    ],
  },
  {
    title: "nav.invoiceAndOverdue",
    icon: "",
    list: [
      {
        title: "nav.menu.UnpaidGroupBalance",
        id: "lnkUnpaidGroupBalance",
        to: `/app/${UNPAID_GROUP_BALANCE}`,
      },
    ],
  },
  {
    title: "nav.helpAndSupport",
    icon: "",
    list: [
      {
        title: "nav.menu.ChangePassword",
        id: "lnkChangePassword",
        to: `${PasswordHelp}`,
        target: "_blank",
      },
      {
        title: "nav.menu.DeviceManagementPortal",
        id: "lnkDeviceManagementPortal",
        to: `/${DEVICE_MANAGEMENT_PORTAL}`,
        ariaLabel: "nav.menu.ssoAriaLabel",
        target: "_blank",
      },
    ],
  },
];

export const ProfileMenuLinks = {
  navLinkProfile: {
    title: "nav.profileMenu.home",
    id: "navLinkProfile",
    to: `http://www.sentarahealthplans.com/brokers`,
  },
};

export const TopNavLinks = {
  navTopEBroker: {
    id: "navTopEBroker",
    title: "nav.topMenu.eBroker",
    to: `/app/${BROKER_DASHBOARD}`,
    active: true,
  },
  navTopBrokerHome: {
    id: "navTopBrokerHome",
    title: "nav.topMenu.brokerHome",
    to: `http://www.sentarahealthplans.com/brokers`,
  },
};

export const FooterNavigation = {
  footerTermsOfUse: {
    dataTestId: "footerTermsOfUse",
    label: "footer.label.termsOfUse",
    url: memberBaseUrl + "/company/policies/terms-of-use-and-disclaimer",
    target: "_blank",
  },
  footerLangAssist: {
    dataTestId: "footerLangAssist",
    label: "footer.label.languagesAssistance",
    url: memberBaseUrl + "/company/policies/language-assistance",
    target: "_blank",
  },
  footerNoticeOfNonDiscrimination: {
    dataTestId: "footerNoticeOfNonDiscrimination",
    label: "footer.label.noticeOfNonDiscrimination",
    url: memberBaseUrl + "/notice-of-nondiscrimination",
    target: "_blank",
  },
  footerContactUs: {
    dataTestId: "footerContactUs",
    label: "footer.label.contactUs",
    url: ContactLink,
  },
};
