import { useAuth } from "providers";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LinkListType } from "utils/model/CommonTypes";

type Props = {
  setIsOpen?: (v: boolean) => void;
  list: Array<LinkListType>;
  setInitialActiveIndex: (v: number) => void;
  no: number;
};

const MenuLinks = (props: Props) => {
  const { setIsOpen = () => {} } = props;
  const { t } = useTranslation();
  const { isAccess } = useAuth();
  const { list, setInitialActiveIndex, no } = props;

  return (
    <ul className="list-group link-list">
      {list.map((item: LinkListType) =>
        isAccess(item.to) ? (
          <li key={item.id} className="list-group-item list-group-item-action">
            <NavLink
              onClick={() => setIsOpen(false)}
              className={({ isActive }) => {
                if (isActive) {
                  setInitialActiveIndex(no);
                }
                return isActive ? "active-link-list" : "";
              }}
              to={item.to}
              data-testid={item.id}
              aria-label={`${t(item.title)} ${t(item.ariaLabel)}`}
              target={item.target}
            >
              {t(item.title)}
            </NavLink>
          </li>
        ) : null
      )}
    </ul>
  );
};

export default MenuLinks;
