import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useLaunchDarkly from "@platform/featuremanagement";
import { useNavigate } from "react-router-dom";
import { UserType } from "utils/model/CommonTypes";
import { AccessMap } from "../../utils/constants/AccessMap";
import { HasR2WAccess } from "@sentara/sentara-api-hooks-core";

interface AuthContextType {
  user?: UserType | null;
  token?: string;
  signOut: (to?: string) => void;
  updateUser: (v: object) => void;
  updateToken: (v: string) => void;
  accessFeatures: string; 
  isAccess: (path: string) => boolean;
}

export const AuthContext = createContext<AuthContextType>({} as any);

interface Props {
  children?: React.ReactNode;
  user?: UserType;
}

export const AuthProvider = ({ children, user: puser }: Props) => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserType | null>(puser ?? null);
  const [token, setToken] = useState<string>("");

  /** Start: Launch Darkly */

  const __user = {
    key: "",
    custom: {
      commercialBroker: false,
      medicareBroker: false,
      hasR2WAccess: user?.hasR2WAccess === HasR2WAccess.YES,
      groupReportingDynamicLinkEnabled: user?.groupReportingDynamicLinkEnabled,
      avMedAgent: "",
    },
  };

  if (user?.person) {
    let medicare = 0;
    let commercial = 0;
    user.lobs?.forEach((lob) => {
      if (lob.name.search("Medicare") >= 0) {
        medicare++;
      } else {
        commercial++;
      }
    });
    // if (user?.carrier?.carrierID === CarrierID.AV_MED) { //FUTURE:- once avmed available 
    // } else 
    if(!user.isUnAuthorizedUser) {
      __user.custom.medicareBroker = medicare > 0;
      __user.custom.commercialBroker = commercial > 0;
    }
  }

  const { apiData: ldData } = useLaunchDarkly(
    user?.person ? JSON.stringify(__user) : {},
    process.env.REACT_APP_LAUNCH_DARKLY_KEY,
    1
  );
  const [accessFeatures, setAccessFeatures] = useState<string>("");

  useEffect(() => {
    if (Array.isArray(ldData)) {
      const featureData: { [x: string]: boolean } = {};
      ldData?.forEach((element: { [x: string]: boolean }) => {
        for (const i in element) {
          featureData[i] = element[i];
        }
      });
      setAccessFeatures(JSON.stringify(featureData));
    }
  }, [ldData]);

  const isAccess = (path: string) => {
    const _path = path.split("?")[0].split("/");

    const pathto = _path.slice(0, 3).join("/");
    if (!accessFeatures) {
      return false;
    }

    if (!AccessMap[pathto]) {
      return true;
    }

    const access = JSON.parse(accessFeatures)[AccessMap[pathto]];
    return !!access;
  };
  /** End: Launch Darkly */

  const signOut = (to = "/logout") => {
    updateToken("");
    setUser(null);
    navigate(to);
  };

  const updateUser = (updatedInfo = {}) => {
    const _user = user ?? { loginID: "", isUnAuthorizedUser: false };
    setUser({ ..._user, ...updatedInfo });
  };

  const updateToken = (token: string) => {
    if (token) {
      setToken(token);
    } else {
      setToken("");
    }
  };

  const value = useMemo(
    () => ({
      user,
      token,
      updateUser,
      updateToken,
      accessFeatures,
      isAccess,
      signOut,
    }),
    [user, token, accessFeatures]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
