import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { TilesIds } from 'utils/constants/Constants';
import {
    IndividualNewIcon,
    EmployerNewIcon,
    MedicareEnrollmentIcon,
    BookOfBusinessesIcon,
    DocumentNoteIcon,
    SupportIcon,
    VirtualVisitIcon,
    PasswordHelpIcon,
} from "@sentaraui/optimahealth_web";
import { BROKER_BOOK_BUSINESS_REPORTING, DEVICE_MANAGEMENT_PORTAL, EMPLOYER_BENEFITS_ENROLLMENT, INDIVIDUAL_NEW_RENEWING, MEDICARE_ENROLLMENT_2025, VIEW_ELIGIBILITY } from 'utils/constants/Routes';
import { useAuth, useBranding, useGlobalState } from 'providers';
import { PasswordHelp } from 'utils/constants/Navigation';
interface Tile {
    id: string;
    name: string;
    icon: React.JSX.Element;
    route?: string;
    link?: string;
}

export const HomeTiles = () => {
    const { brandConfig } = useBranding();
    const { refreshAccordion } = useGlobalState();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [userType, setUserType] = useState<'commercial' | 'medicare' | 'commercialMedicare' | null>(null);

    const tileActions = (tile: Tile) => {
        if (tile.route) {
            navigate(`/app/${tile.route}`);
            refreshAccordion();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 500);
        }
        if (tile.link) window.open(tile.link, '_blank');
    };
    const tileData: Tile[] = [
        { id: TilesIds.Title1, name: t('nav.tile.viewEligibility'), icon: <DocumentNoteIcon />, route: VIEW_ELIGIBILITY },
        { id: TilesIds.Title2, name: t('nav.tile.passwordHelp'), icon: <PasswordHelpIcon />, link: `${PasswordHelp}` },
        { id: TilesIds.Title3, name: t('nav.tile.bookOfBusiness'), icon: <BookOfBusinessesIcon />, route: BROKER_BOOK_BUSINESS_REPORTING },
        { id: TilesIds.Title4, name: t('nav.tile.individual'), icon: <IndividualNewIcon />, route: INDIVIDUAL_NEW_RENEWING },
        { id: TilesIds.Title5, name: t('nav.tile.employer'), icon: <EmployerNewIcon />, route: EMPLOYER_BENEFITS_ENROLLMENT },
        { id: TilesIds.Title6, name: t('nav.tile.medicare'), icon: <MedicareEnrollmentIcon />, route: MEDICARE_ENROLLMENT_2025 },
        { id: TilesIds.Title8, name: t('nav.tile.deviceManagement'), icon: <VirtualVisitIcon />, link: `/${DEVICE_MANAGEMENT_PORTAL}` },
        { id: TilesIds.Title7, name: t('nav.tile.support'), icon: <SupportIcon />, link: brandConfig.link.supportTileLink },
    ];

    useEffect(() => {
        if (user?.lobs) {
            const types = user?.lobs.map((lob) => lob.name);
            if (types.includes('Medicare') && types.length > 1) {
                setUserType('commercialMedicare');
            } else if (types.includes('Medicare')) {
                setUserType('medicare');
            } else {
                setUserType('commercial');
            }
        }
    }, [user]);

    const getTiles = (): Tile[] => {
        let filteredTiles: Tile[] = [];

        if (userType === 'commercialMedicare') {
            filteredTiles = tileData.filter(tile => tile.id !== TilesIds.Title7 && tile.id !== TilesIds.Title8);
        } else if (userType === 'commercial') {
            filteredTiles = tileData.filter(tile => tile.id !== TilesIds.Title6 && tile.id !== TilesIds.Title8);
        } else if (userType === 'medicare') {
            filteredTiles = tileData.filter(tile => tile.id !== TilesIds.Title1 && tile.id !== TilesIds.Title2 && tile.id !== TilesIds.Title4 && tile.id !== TilesIds.Title5);
        }
        return filteredTiles.slice(0, 6);
    };

    return (
        <>
            {userType && getTiles().map((tile) => (
                <button
                    onClick={() => tileActions(tile)}
                    data-testid={`${tile.id}`}
                    className="grid-col d-flex flex-column justify-content-center align-items-center bg-transparent home-tile-link"
                    key={tile.id}
                >
                    <span>{tile.icon}</span>
                    {tile.name}
                </button>
            ))}
        </>
    );
};

export default HomeTiles;
